import './App.css';
import Navigation from './navigation';


const App = () => {
  return (
    <div className="App">
      <Navigation />

    </div>
  );
}

export default App;
