import { Banner } from "../../components/Banner";
import { Auth } from "../../components/Auth";


const Home = () => {
    return(
        <>
            <Banner />
            <Auth />
        </>
        
    )
}


export default Home;